import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import '../styles/Home.css';
import '../styles/SignIn.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { TextField, InputAdornment, IconButton, FormControl, InputLabel, Input, FormHelperText  } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useCookies } from 'react-cookie';
import { RegistrationFetch, LoginFetch, useAuthFetch, MailVerifyFetch, UserFetch, MailTokenVerifyFetch } from './api.jsx';
import api from '../config';
import { logger } from '../logger';
import { use } from 'i18next';
import { useLocation } from 'react-router-dom';



function SignIn() {
  const { t } = useTranslation();

  const [cookies, setCookie] = useCookies(['token']);

  const { fetchWithAuth, token } = useAuthFetch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenMail = searchParams.get('token');


  useEffect(() => {
    const fetchUserIsActive = async () => {
      try {
        const requestBody = {
          token: tokenMail
        }
        const userInfo = await MailTokenVerifyFetch(api.APP_URL_USER_API, requestBody, fetchWithAuth, );
        logger.log('User info:', userInfo);
        if (userInfo.is_verified == true) {
            window.location.href = '/sign-in';
        } else {
            logger.log('Server problem');
        }
      } catch (error) {
        logger.error('Error fetching user info:', error);
      }
    };
    fetchUserIsActive();
    }, []); 


  return (
    <div>
        Waiting for verification
    </div>
  );
}

export default SignIn;
