import React, { useEffect } from 'react';

const CrashComponent = () => {
  useEffect(() => {
    throw new Error("This component has crashed!");
  }, []);

  return <div>This page will crash immediately.</div>;
};

export default CrashComponent;
