// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-data-button {
  background-color: #dce6fa;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.show-data-button-invisible {
  display: none;
}

.show-data-button-loader{
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  animation: spin 1s linear infinite;
  margin: auto;
}

@media only screen and (max-width: 850px) {
  #search-icon-button {
    color: #f8718a;
    height: 3vh;
    width: 3vh;
  }
}

@media only screen and (min-width: 851px) {
  #search-icon-button {
    color: #f8718a;
    height: 4vh;
    width: 4vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/ShowDataButton.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE;IACE,cAAc;IACd,WAAW;IACX,UAAU;EACZ;AACF;;AAEA;EACE;IACE,cAAc;IACd,WAAW;IACX,UAAU;EACZ;AACF","sourcesContent":[".show-data-button {\n  background-color: #dce6fa;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n.show-data-button-invisible {\n  display: none;\n}\n\n.show-data-button-loader{\n  border: 4px solid #f3f3f3;\n  border-top: 4px solid #3498db;\n  border-radius: 50%;\n  width: 5vh;\n  height: 5vh;\n  animation: spin 1s linear infinite;\n  margin: auto;\n}\n\n@media only screen and (max-width: 850px) {\n  #search-icon-button {\n    color: #f8718a;\n    height: 3vh;\n    width: 3vh;\n  }\n}\n\n@media only screen and (min-width: 851px) {\n  #search-icon-button {\n    color: #f8718a;\n    height: 4vh;\n    width: 4vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
