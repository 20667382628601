import React, { useState, useRef, useEffect } from 'react';
import Footer from './Footer';
import '../styles/Home.css';
import { SearchBar } from './SearchBar';
import { SearchResultsList } from './SearchResultsList';
import HomeMapClick from './HomeMapClick';
import SubscryptionLimitReached from './SubscryptionLimitReached';
import { ShowDataButton } from './ShowDataButton';
import Roles from './Roles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { icon } from './anim.js';
import { logger } from '../logger';
import api from '../config';
import { set } from 'animejs';
import { useCookies } from 'react-cookie';
import md5 from 'md5';
import { loadDataFetch, useAuthFetch, AddressByLatLng } from './api.jsx';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import TestingPopUp from './TestingPopUp.jsx';
import SubscryptionPopUp from './SubscryptionPopUp.jsx';

function Home() {
  const [results, setResults] = useState([]);
  const [input, setInput] = useState('');
  const [location, setLocation] = useState('');
  const [addresses, setAddresses] = useState('');
  const [addressId, setAddressId] = useState('');
  const [isResultClicked, setIsResultClicked] = useState(false);
  const [selectedRole, setSelectedRole] = useState('without role');
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [selectedPreferencesTransformed, setSelectedPreferencesTransformed] =
    useState([]);
  const SearchListRef = useRef(null);
  const [alarm, setAlarm] = useState('');
  const [selectedPreferencesSearch, setSelectedPreferencesSearch] = useState(
    [],
  );
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { fetchWithAuth, token } = useAuthFetch();
  const [cookies, setCookie] = useCookies(['userID']);
  const [subscryptionLimitReached, setSubscryptionLimitReached] = useState(false);
  const [unauthorizedShow, setUnauthorizedShow] = useState(false);
  const [popUpType, setPopUpType] = useState('');
  useEffect(() => {
    if (cookies.userID) {
      loadData(cookies.userID);
    } else {
      handleNewUserRegistration();
    }
  }, []);

  const loadData = async (id) => {
    try {
      const storedData = localStorage.getItem('myData');



      let request = {};
      if (storedData) {
        request = JSON.parse(storedData);
      }
      if (Object.keys(request).length === 0) {
        return;
      }
      if (!request.APP_VERSION || request.APP_VERSION < api.APP_VERSION) {
        logger.log("REMOVE DATA");
        localStorage.removeItem('myData');
        return;
      }
      logger.log('Request:', request);

      if (request.results) {
      setInput((prevInput) => {
        if (request.results.length === 0) {
          return prevInput;
        }
        if (prevInput === '') {
          return request.results[0].fullAddress;
        }
        return prevInput;
      });
      }

      handleSetCustomAdressesAndObjects(request);
      handleSetPreferences(request);
      const transformedCategories = request.categories && request.categories.map((category) => ({
        id: category,
      }));
      setSelectedPreferencesTransformed(transformedCategories);
      i18n.changeLanguage(request.language);
      setAddresses(request.addresses);
    } catch (error) {
      console.error('Error getting report:', error);
    }
  };
  const generateUserID = () => {
    const timestamp = new Date().getTime();
    const randomNumber =
      Math.floor(Math.random() * (999999999 - 1000 + 1)) + 1000;
    const combinedString = timestamp.toString() + randomNumber.toString();
    const userID = md5(combinedString);
    return userID;
  };

  const handleNewUserRegistration = async () => {
    const userID = generateUserID();
    setCookie('userID', userID); // Set userID cookie
  };

  const handleLanguageChange = (lng) => {
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng);
  };
  const buttonRef = useRef(null);

  const handleEnterPress = () => {
    //TODO: Add logic to handle enter press
    /*
    if (results.length !== 0) {
      setInput(results[0]);
    }
    */
    setIsResultClicked(true);
    if (buttonRef.current) {
      setTimeout(() => {
        buttonRef.current.click();
      }, 10); // Czas w milisekundach (tutaj 100000ms = 100s)
    }
  };

  const handleResultClick = (result) => {
    setInput(result.fullAddress);
    setAddressId(result);
    setTimeout(handleEnterPress, 20);
    setIsResultClicked(true);
  };

  const handleSearchBarChange = (value) => {
    setAlarm('');
    setInput(value);
    setIsResultClicked(false);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handlePreferencesSelect = (preferences) => {
    setSelectedPreferences(preferences);
  };

  const handleSetCustomAdressesAndObjects = (data) => {
    const customObjectsAndAdresses = [];
    data.requested_addresses && data.requested_addresses.forEach((item) => {
      customObjectsAndAdresses.push(item);
    });
    data.requested_objects && data.requested_objects.forEach((item) => {
      customObjectsAndAdresses.push({
        name: item.name,
        category: item.main_category,
        sub_category: item.category,
        id: item.id,
      });
    });
    setSelectedPreferencesSearch(customObjectsAndAdresses);
  };

  const handleSetPreferences = (data) => {
    const preferences = [];
    data.transformedPreferences && data.transformedPreferences.forEach((item) => {
      preferences.push({
        name: item.category,
      });
    });
    setSelectedPreferences(preferences);
  };

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (SearchListRef.current && !SearchListRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsResultClicked(true);
        }, 100);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsResultClicked]);

  const handleLocationClick = async (latlng) => {
    setUnauthorizedShow(true);
    logger.log('Location clicked:', latlng);
    const requestBody = {
      lat: latlng.lat,
      lng: latlng.lng,
    };
    try {
      const data = await AddressByLatLng(api.APP_URL_USER_API, requestBody, cookies.token, fetchWithAuth);
      
      // Obsługa, gdy `data` jest obiektem
      if (Array.isArray(data)) {
      
        setInput(data[0].fullAddress);
        setAddressId(data[0].id);
      } else {
        setInput(data.fullAddress);
        setAddressId(data.id);
      }
      setLocation(latlng);
      setIsResultClicked(true);
    } catch (error) {
      console.error('Error getting address from coordinates:', error);
    }

  }

  
  const handleGetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(position);

          const userLocation = { lat: latitude, lng: longitude };
          setLocation(userLocation);
          handleLocationClick(userLocation);
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        (error) => {
          console.error("Error getting location:", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Error getting location");
    }
  };

  const handleSubscryptionLimitReachedClose = () => {
    setUnauthorizedShow(false);
    setPopUpType('');
  };

  const handleSubscryptionLimitReachedCancel = () => {
    setPopUpType('');
  };


  return (
    <div className="home-container">
      {(subscryptionLimitReached && unauthorizedShow && popUpType==='subscryption') && (
        <SubscryptionPopUp 
          onClose={handleSubscryptionLimitReachedCancel}
        />
      )}
      {(subscryptionLimitReached && unauthorizedShow && popUpType==='testing') && (
        <TestingPopUp 
          onClose={handleSubscryptionLimitReachedCancel}
        />
      )}
      {(subscryptionLimitReached && unauthorizedShow && popUpType==='') && (
        <SubscryptionLimitReached 
          onClose={handleSubscryptionLimitReachedClose}
          setPopUpType={setPopUpType}
        />
      )}
      <div className="language-select-container">
        <Link to="/">
          <motion.button
            className="logo_home"
            title={t('Search Page')}
            whileHover={{ scale: 1 }} // Przykładowa animacja przy najechaniu
            whileTap={{ scale: 1 }} // Przykładowa animacja przy kliknięciu
          >
            <img
              src={'/images/15min_logo.svg'}
              alt="Red Cross"
              className="centered-img-cross"
            ></img>
          </motion.button>
        </Link>
      </div>
      <div className="search-bar-container">
        <div className="column search-bar-and-results results-container">
          <h1 className="home-description-title">
          </h1>
          <SearchBar
            setResults={setResults}
            showDataRef={buttonRef}
            input={input}
            addresses={addresses}
            addressId={addressId}
            setInput={handleSearchBarChange}
            setIsResultClicked={setIsResultClicked}
            onEnterPress={handleEnterPress}
            searchBarClassName={
              results && results.length > 0 && !isResultClicked
                ? 'border-bottom home-searchBar'
                : 'home-searchBar'
            }
            selectedPreferences={selectedPreferences}
            preferencesSearchData={selectedPreferencesSearch}
            transformedPreferences={selectedPreferencesTransformed}
            setAlarm={setAlarm}
            IconVisibility={true}
            results={results}
            setUnauthorizedShow={setUnauthorizedShow}
          />
          <div className="relative" ref={SearchListRef}>
            <div className="home-alarm">{t(alarm)}</div>
            {!isResultClicked && (
              <SearchResultsList
                results={results}
                onResultClick={handleResultClick}
                searchResultsListClassName="home-search-result-list"
                searchResultsClassName="home-search-list"
              />
            )}
          <div className='location-button' onClick={handleGetUserLocation}>
            <div>
              <Icon icon="uil:user-location" id="location-icon-button" />
            </div>
          </div>
          <div className="map-container-home">
            <HomeMapClick onLocationClickHome={handleLocationClick} location={location}/>
          </div>

          </div>
        </div>
      </div>

        



      <Footer useMargin={true} setSubscryptionLimitReached={setSubscryptionLimitReached}/>
    </div>
  );
}

export default Home;
