// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-result {
  padding: 3.77875vh 3vh;
  font-family: 'Lato', sans-serif;
  font-size: 3vh;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-result:hover {
  background-color: #bcc6da;
}

@media only screen and (max-width: 1300px) {
  .search-result {
    padding: 3.27875vh 2vh;
    font-family: 'Lato', sans-serif;
    font-size: 2vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media only screen and (max-width: 600px) {
  .search-result {
    padding: 2.27875vh 1.2vh;
    font-family: 'Lato', sans-serif;
    font-size: 2vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media only screen and (max-width: 600px) {
  .search-result {
    padding: 2.27875vh 1.5vh;
    font-family: 'Lato', sans-serif;
    font-size: 1.7vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/SearchRolesResult.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,+BAA+B;EAC/B,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,sBAAsB;IACtB,+BAA+B;IAC/B,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,wBAAwB;IACxB,+BAA+B;IAC/B,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,wBAAwB;IACxB,+BAA+B;IAC/B,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":[".search-result {\n  padding: 3.77875vh 3vh;\n  font-family: 'Lato', sans-serif;\n  font-size: 3vh;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.search-result:hover {\n  background-color: #bcc6da;\n}\n\n@media only screen and (max-width: 1300px) {\n  .search-result {\n    padding: 3.27875vh 2vh;\n    font-family: 'Lato', sans-serif;\n    font-size: 2vh;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .search-result {\n    padding: 2.27875vh 1.2vh;\n    font-family: 'Lato', sans-serif;\n    font-size: 2vh;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .search-result {\n    padding: 2.27875vh 1.5vh;\n    font-family: 'Lato', sans-serif;\n    font-size: 1.7vh;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
