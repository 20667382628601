import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';

const SubscryptionPopUp = ({ onClose }) => {
  const { t } = useTranslation();


  const FREE_LIMIT = 3;
  const REQUEST_LIMIT = 50;
  const MONTHLY_COST = 20; 
  const ANNUAL_COST = 240; 
  const DISCOUNT = 25; 
  const AVAILABLE_SEATS = 10;

  const monthlyDiscounted = MONTHLY_COST * (1 - DISCOUNT);
  const annualDiscounted = ANNUAL_COST * (1 - DISCOUNT);


  const handleSubscriptionTermsClick = () => {
    window.location.href = 'https://docs.google.com/document/d/1bPYsCRE7AJvBium_ArPdpB4vPpwbWE8QInNToa83I-E/edit?usp=sharing';
  };


  const handleMonthlySubscriptionClick = () => {
    const placeholder = t('monthly_subscription');
    window.location.href = `/about-us?topic=Subscription&placeholder=${encodeURIComponent(placeholder)}`;
  };

  const handleAnnualSubscriptionClick = () => {
    const placeholder = t('annual_subscription');
    window.location.href = `/about-us?topic=Subscription&placeholder=${encodeURIComponent(placeholder)}`;
  };

  return (
    <div className="modal-overlay">
      <div className="modal-contents-subscryption">
        <div className="sub-comment" style={{ marginTop: '5vh' }}>
          <button className="close-button" onClick={onClose}>
            <Icon icon="material-symbols-light:close" id="close-icon-button" />
          </button>
        </div>
        <div className="sub-comment">
          <strong>{t('Subscription:')}</strong>{' '}
          {t(
            `You will have the option to cancel your subscription with a full refund within a week. You can read more about the`
          )}{' '}
          <a href="#" onClick={handleSubscriptionTermsClick}>
            {t('terms of subscryption here')}
          </a>{' '}
        </div>
        <div className="sybscryption-button-choose">
          <button className="subscryption-button" onClick={handleMonthlySubscriptionClick}>
            {t('Monthly')}
          </button>
          <button className="subscryption-button" onClick={handleAnnualSubscriptionClick}>
            {t('Annual')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscryptionPopUp;
