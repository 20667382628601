import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import '../styles/Home.css';
import '../styles/SignIn.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { TextField, InputAdornment, IconButton, FormControl, InputLabel, Input, FormHelperText  } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useCookies } from 'react-cookie';
import { RegistrationFetch, LoginFetch, useAuthFetch, MailVerifyFetch, UserFetch, ForgotPasswordMail, ChangePassword } from './api.jsx';
import api from '../config';
import { logger } from '../logger';
import { use } from 'i18next';
import { set } from 'animejs';
import { useLocation } from 'react-router-dom';


function ResetPassword() {
  const { t } = useTranslation();
  const [option, setOption] = useState('default');
  const [optionSmall, setOptionSmall] = useState('default');
  const [showPassword, setShowPassword] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [passwordMailSend, setPasswordMailSend] = React.useState(false);
  const isDefault = optionSmall !== 'sign-up' && optionSmall !== 'log-in' && optionSmall !== 'pre-log-in';
  const [cookies, setCookie] = useCookies(['token']);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenMail = searchParams.get('token');
  const { fetchWithAuth, token } = useAuthFetch();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 450 || window.innerWidth / window.innerHeight < 1);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 450 || window.innerWidth / window.innerHeight < 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const hadleChangePasswordClick = async () => {
    if (valuesPassword.password.length < 8) {
        setValuesPassword({ ...valuesPassword, passwordError: true });
        return;
    }
    const requestBody = {
        token: tokenMail,
        password: valuesPassword.password
    }
    try {
        const response = await ChangePassword(api.APP_URL_USER_API, requestBody, fetchWithAuth);
        setOptionSmall('success');
        setOption('success');
    } catch (error) {
        logger.error('Error fetching user info:', error);
    }
  }

  const handleRedirectLogIn = () => {
    window.location.href = '/sign-in';
}

const handleChangeValuesForgotPassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };



  const [valuesPassword, setValuesPassword] = useState({
    password: '',
    passwordError: false,
  });

  return (
    <div>
      {isSmallScreen === true ? (
        <div className="sign-in-container"> 
          <div className="language-select-container">
          <div 
            className={`${optionSmall === 'default' ||  optionSmall === 'success' ||  optionSmall === 'pre-log-in' ?  '' : 'language-select-sign-up-small'}`}
          >
          <Link to="/sign-in">
            <motion.button
              className="logo_home"
              style={isDefault ? { backgroundColor: '#DCE6FA' } : {}}
              title={t('Search Page')}
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 1 }}
            >
              <img
                src={'/images/15min_logo.svg'}
                alt="Red Cross"
                className="centered-img-cross"
                style={isDefault ? { backgroundColor: '#DCE6FA' } : {}}
              ></img>
            </motion.button>
          </Link>
          </div>
          </div>
          <div className='search-bar-container-small'>
            {optionSmall === 'default' ? (
              <div>
              <div className='forgot-password-title-small'>
                <div className="forgot-password-title">
                  <div className='pre-log-in-title-text'>
                    {t('Please enter your new password')}
                  </div>
                </div>
              </div>
              <div className='text-fields-div-small'>
                <TextField 
                  id="standard-basic" 
                  error={valuesPassword.passwordError}
                  label={t('Password')} 
                  variant="standard"  
                  className='text-field-default'
                  value={valuesPassword.password} 
                  helperText={valuesPassword.passwordError ? t('Password must be longer than 8 characters') : ' '}
                  onChange={handleChangeValuesForgotPassword('password')}
                />
              </div>
              <div 
                className={`sign-up-button-small ${valuesPassword.password === '' ? 'send-empty' : ''}`}
                onClick={() => hadleChangePasswordClick()}  // Przeniesione onClick na odpowiedni element
              >
                <div className='sign-up-button-label'>{t('Change password')}</div>
              </div>

            </div>
            ) : optionSmall === 'success' ? (
                <div className='pre-log-in-title-small'>
                <div className='pre-log-in-title-text'>{t('Password changed!')}</div>
                <div className='pre-log-in-subtitle-text'>{t('You can now log in with your new password')}</div>
                <div>
                  <div className='sign-up-button-small' onClick={() => handleRedirectLogIn()}>
                    <div className='sign-up-button-label'>{t('Log in')}</div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Footer useMargin={true} />
        </div>
        
      ) : (
        <div className="sign-in-container">
        <div className="language-select-container">
          <div className="language-select-sign-up">
          <Link to="/sign-in">
            <motion.button
              className="logo_home"
              style={{backgroundColor: '#DCE6FA'}}
              title={t('Search Page')}
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 1 }}
            >
              <img
                src={'/images/15min_logo.svg'}
                alt="Red Cross"
                className="centered-img-cross"
                style={{backgroundColor: '#DCE6FA'}}
              ></img>
            </motion.button>
          </Link>
          </div>
        </div>
        <div className="search-bar-container">
          <div className='sign-up-left-section'>
            <h1 className="sign-in-description-title">
              {t(
                'Imagine that getting to the points in the city that are important to you requires only a short walk.'
              )}
            </h1>
            <h2 className="sign-in-description-second-title">
              {t(
                'The idea of a 15-minute city is just such a vision - a life of convenience and proximity, where you will do your daily errands without long journeys, enjoying greater freedom.'
              )}
            </h2>
          </div>
          <div className="sign-up-right-section">
            <div>
            {option === 'default' ? (
              <div>
              <div className='text-fields-div' style={{marginTop: '15vh'}}>
                <div className="forgot-password-title">
                  <div className='forgot-password-title-text'>
                    {t('Please enter your new password')}
                  </div>
                </div>
              </div>
              <div className='text-fields-div'>
                <TextField 
                  id="standard-basic" 
                  error={valuesPassword.passwordError}
                  label={t('Password')} 
                  variant="standard"  
                  className='text-field-default'
                  value={valuesPassword.password} 
                  helperText={valuesPassword.passwordError ? t('Password must be longer than 8 characters') : ' '}
                  onChange={handleChangeValuesForgotPassword('password')}
                />
              </div>
              <div 
                className={`send-button ${valuesPassword.password === '' ? 'send-empty' : ''}`}
                onClick={() => hadleChangePasswordClick()}  // Przeniesione onClick na odpowiedni element
              >
                <div className='sign-up-button-label'>{t('Change password')}</div>
              </div>

            </div>
            ) : option === 'success' ? (
                <div className='pre-log-in-title'>
                <div className='pre-log-in-title-text'>{t('Password changed!')}</div>
                <div className='pre-log-in-subtitle-text'>{t('You can now log in with your new password')}</div>
                <div>
                  <div className='sign-up-button' onClick={() => handleRedirectLogIn()}>
                    <div className='sign-up-button-label'>{t('Log in')}</div>
                  </div>
                </div>
              </div>
            ) : null}
            </div>
            </div>
        </div>
        <Footer useMargin={true} SignIn={true} />
      </div>
      )}
    </div>
  );
}

export default ResetPassword;
