// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Leaflet Map Container */
.leaflet-container {
  width: 130%;
  height: 200%;
  margin-top: 3vh;
  margin-left: 3vh;
}
/* Leaflet Map Tiles */
.leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

/* Leaflet Map Controls */
.leaflet-control {
  position: relative;
  z-index: 1000;
  pointer-events: auto;
}

/* Leaflet Map Popup */
.leaflet-popup {
  position: absolute;
}

/* Leaflet Map Marker */
.leaflet-marker-icon {
  width: 25px;
  height: 41px;
}

/* Leaflet Map Marker Icon */
.leaflet-marker-icon img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1300px) {
  .leaflet-container {
    width: 130%;
    height: 200%;
    margin-top: 2vh;
    margin-left: 3vh;
  }
}

@media only screen and (max-width: 800px) {
  .leaflet-container {
    width: 130%;
    height: 200%;
    margin-top: 1vh;
    margin-left: 1vh;
  }
}

@media only screen and (max-width: 451px) {
  .leaflet-container {
    width: calc(100% - 4vh);
    height: 200%;
    margin-top: 2vh;
    margin-left: 2vh;
    margin-right: 2vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Leaflet.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;AACA,sBAAsB;AACtB;EACE,0CAA0C;EAC1C,iCAAiC;EACjC,4BAA4B;AAC9B;;AAEA,yBAAyB;AACzB;EACE,kBAAkB;EAClB,aAAa;EACb,oBAAoB;AACtB;;AAEA,sBAAsB;AACtB;EACE,kBAAkB;AACpB;;AAEA,uBAAuB;AACvB;EACE,WAAW;EACX,YAAY;AACd;;AAEA,4BAA4B;AAC5B;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;EACnB;AACF","sourcesContent":["/* Leaflet Map Container */\n.leaflet-container {\n  width: 130%;\n  height: 200%;\n  margin-top: 3vh;\n  margin-left: 3vh;\n}\n/* Leaflet Map Tiles */\n.leaflet-tile {\n  image-rendering: -webkit-optimize-contrast;\n  image-rendering: -moz-crisp-edges;\n  image-rendering: crisp-edges;\n}\n\n/* Leaflet Map Controls */\n.leaflet-control {\n  position: relative;\n  z-index: 1000;\n  pointer-events: auto;\n}\n\n/* Leaflet Map Popup */\n.leaflet-popup {\n  position: absolute;\n}\n\n/* Leaflet Map Marker */\n.leaflet-marker-icon {\n  width: 25px;\n  height: 41px;\n}\n\n/* Leaflet Map Marker Icon */\n.leaflet-marker-icon img {\n  width: 100%;\n  height: 100%;\n}\n\n@media only screen and (max-width: 1300px) {\n  .leaflet-container {\n    width: 130%;\n    height: 200%;\n    margin-top: 2vh;\n    margin-left: 3vh;\n  }\n}\n\n@media only screen and (max-width: 800px) {\n  .leaflet-container {\n    width: 130%;\n    height: 200%;\n    margin-top: 1vh;\n    margin-left: 1vh;\n  }\n}\n\n@media only screen and (max-width: 451px) {\n  .leaflet-container {\n    width: calc(100% - 4vh);\n    height: 200%;\n    margin-top: 2vh;\n    margin-left: 2vh;\n    margin-right: 2vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
