// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .error-boundary h1 {
    color: #3044CA;
  }
  
  .error-boundary p {
    margin: 10px 0;
  }
  
  .error-boundary button {
    cursor: pointer;
    width: 30vw;
    height: 7vh;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 5vh;
    background-color: #24254C;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
    color: white;
    font-size: large;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/ErrorBoundary.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;EAClB","sourcesContent":[".error-boundary {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    text-align: center;\n  }\n  \n  .error-boundary h1 {\n    color: #3044CA;\n  }\n  \n  .error-boundary p {\n    margin: 10px 0;\n  }\n  \n  .error-boundary button {\n    cursor: pointer;\n    width: 30vw;\n    height: 7vh;\n    margin-left: 5vw;\n    margin-right: 5vw;\n    margin-top: 5vh;\n    background-color: #24254C;\n    text-align: center;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    border-radius: 8px;\n    color: white;\n    font-size: large;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
